body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* fix close button with light modal */
.text-light .close {
  color: inherit;
}

/* fix box shadow around "links" that are really buttons*/
.btn-link:focus {
  box-shadow: none !important;
}

.clickable:hover {
  cursor: pointer;
  user-select: none;
}

/* support confirm dialogs on top of everything else */
.modal-backdrop.confirm-backdrop {
  z-index: 2050; /* normal is 1050 */
}
.modal.confirm-modal {
  z-index: 2055; /* normal is 1055 */
}
